<template>
  <div class="row">
    <span v-if="loading">
      <q-inner-loading :showing="true">
        <q-spinner size="3em" color="primary" />
      </q-inner-loading>
    </span>
    <slot v-else-if="!loading && customEmpty" name="customEmpty"></slot>
    <span class="no-material" v-else>
      {{ emptyContent }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'LoadingOrEmpty',
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    customEmpty: {
      type: Boolean,
      default: false
    },
    emptyContent: {
      type: String,
      default: () => ''
    }
  }
}
</script>
