<template>
  <div :class="{ 'new-template edit-template': true, 'no-modify': noModify }">
    <q-page-container>
      <q-page>
        <fragment v-if="file">
          <StepTwo
            :file="file"
            :isEdit="true"
            @dataFileChanged="dataFileChanged"
            @nameFileChanged="nameFileChanged"
            @clickOnSubmit="clickOnSubmit"
          />
        </fragment>
        <LoadingOrEmpty v-else :loading="getLoadingModel" />
      </q-page>
    </q-page-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import StepTwo from '@/components/new-template/StepTwo.vue'
import LoadingOrEmpty from '@/components/global/LoadingOrEmpty'
import isEqual from 'lodash.isequal'

export default {
  name: 'EditTemplate',
  components: {
    LoadingOrEmpty,
    StepTwo
  },
  data() {
    return {
      file: null,
      noModify: false,
      dataChanged: false,
      nameChanged: false,
      isOnSubmit: false
    }
  },
  async created() {
    const { modelId } = this.$route.params
    const response = await this.fetchModel({ modelId })

    let result
    if (response.modelType === 'EXCEL') {
      const url =
        response.modelStatus === 'FIRST_UPLOAD'
          ? response.s3UrlOriginal
          : response.s3Url

      result = await fetch(url).then(res => res)
    } else {
      result = await fetch(response.s3Url).then(res => res.json())
    }

    let name = ''
    name = response.modelFileName.split('.')
    name.splice(name.length - 1, 1)
    name = name ? name.join('.') : ''

    this.file = {
      result,
      name: response.modelName || name,
      modelId: response.modelId,
      typeShort: response.modelType,
      modelStatus: response.modelStatus
    }

    await this.fetchModelDone()
    this.$store.commit('templates/setDocumentType', 'template')
  },
  computed: {
    ...mapGetters('documents', ['getLoadingModel', 'getDeleteStatus']),
    ...mapGetters('users', ['getCurrentUser']),
    ...mapGetters('templates', [
      'getShowNewFields',
      'getEdit',
      'getEntityList',
      'getSingleEntity',
      'getDocumentType',
      'newReportData',
      'getReportDataResponse',
      'getReportName',
      'getEntityId',
      'getOldLabel',
      'getEntityListDublicate',
      'getPlacedStatus',
      'getDocFocused',
      'getErrorEntityName',
      'getShowUnsavedChanges'
    ])
  },
  mounted() {
    if (!this.getCurrentUser) return
    this.noModify = !this.getCurrentUser?.userRights?.rightModifyModel
  },
  methods: {
    ...mapActions('documents', ['fetchModel', 'fetchModelDone']),
    ...mapActions('templates', ['setShowUnsavedChanges']),
    dataFileChanged(status) {
      this.dataChanged = status
    },
    nameFileChanged(status) {
      this.nameChanged = status
    },
    clickOnSubmit() {
      this.isOnSubmit = true
    }
  },
  watch: {
    getCurrentUser: function(currentUser) {
      const { userRights } = currentUser
      this.noModify = !userRights?.rightModifyModel
    }
  },
  beforeDestroy() {
    this.file = null
  },
  beforeRouteLeave(to, from, next) {
    const isFileEXCEL = this.file.typeShort === 'EXCEL'
    if (
      this.isOnSubmit ||
      (this.getShowUnsavedChanges.status === null && !isFileEXCEL)
    ) {
      this.isOnSubmit = false
      next()
      return
    }
    let _isEqual = false
    if (this.getEntityListDublicate && this.getEntityListDublicate.length) {
      _isEqual = isEqual(this.getEntityList, this.getEntityListDublicate)
    }

    if (!_isEqual || this.dataChanged || this.nameChanged || isFileEXCEL) {
      const payload = {
        to,
        from,
        next
      }

      if (this.getDeleteStatus == false) {
        this.setShowUnsavedChanges({ status: true, payload })
      } else {
        next()
      }
    } else {
      next()
    }
  }
}
</script>
